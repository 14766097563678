import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Button, Form, message } from "antd";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";

const EditConfigSetting = () => {
  const apiUrl = process.env.REACT_APP_ConfigSetting;

  const [form] = Form.useForm();
  const [currencyRate, setCurrencyRate] = useState("");
  const [bonusLimit, setBonusLimit] = useState("");
  const [payoutLowerLimit, setPayoutLowerLimit] = useState("");
  const [numbersPerDay, setNumberPerDayLimit] = useState("");
  const [stSimLimit, setStSimLimit] = useState("");
  const [stAdditionalSimLimit, setStAdditionalSimLimit] = useState("");

  const fetchConfigSetting = async () => {
    try {
      const response = await axios.get(apiUrl);
      const configSetting = response.data.body;
      setCurrencyRate(configSetting.currency_rate);
      setBonusLimit(configSetting.bonus_limit);
      setPayoutLowerLimit(configSetting.payout_lower_limit);
      setNumberPerDayLimit(configSetting.numbers_count);
      setStSimLimit(configSetting.special_tester_sim_limit);
      setStAdditionalSimLimit(
        configSetting.special_tester_additional_sim_limit
      );
    } catch (error) {
      console.error("Error fetching config setting:", error);
    }
  };

  useEffect(() => {
    fetchConfigSetting();
  }, []);

  const handleCurrencyRateChange = (e) => {
    setCurrencyRate(e.target.value);
  };

  const handleBonusLimitChange = (e) => {
    setBonusLimit(e.target.value);
  };

  const handlePayoutLowerLimitChange = (e) => {
    setPayoutLowerLimit(e.target.value);
  };

  const handleNumbersPerDayLimitChange = (e) => {
    setNumberPerDayLimit(e.target.value);
  };
  const handleStSimLimitChange = (e) => {
    setStSimLimit(e.target.value);
  };
  const handleStAdditonalSimLimitChange = (e) => {
    setStAdditionalSimLimit(e.target.value);
  };
  const handleSubmit = async () => {
    try {
      const response = await axios.put(apiUrl, {
        currency_rate: currencyRate,
        bonus_limit: bonusLimit,
        payout_lower_limit: payoutLowerLimit,
        numbers_count: numbersPerDay,
        st_sim_limit: stSimLimit,
        st_additional_sim_limit: stAdditionalSimLimit,
      });

      if (response.status === 200) {
        message.success("Settings updated successfully");
      } else {
        throw new Error("Failed to edit settings");
      }
    } catch (error) {
      console.error("Error editing settings:", error);
      message.error("Failed to edit settings");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",

          marginBottom: 16,
        }}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} sm={8} md={6} lg={12}>
            <Form
              form={form}
              onFinish={handleSubmit}
              className="mx-10"
              initialValues={{
                currencyRate: currencyRate,
                bonus_limit: bonusLimit,
                payout_lower_limit: payoutLowerLimit,
                numbers_count: numbersPerDay,
                special_tester_sim_limit: stSimLimit,
                special_tester_additional_sim_limit: stAdditionalSimLimit,
              }}
            >
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">
                  Edit Configuration Setting
                </h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                {/* Fields */}
                <Input
                  value={currencyRate}
                  onChange={handleCurrencyRateChange}
                  placeholder="Currency Rate"
                  addonBefore="Currency Rate"
                />
                <Input
                  value={numbersPerDay}
                  onChange={handleNumbersPerDayLimitChange}
                  placeholder="SIM limit per operator(Manual Testing), per day"
                  addonBefore="SIM limit per operator, per day"
                />
                <Input
                  value={stSimLimit}
                  onChange={handleStSimLimitChange}
                  placeholder="SIM limit per operator(Special Testing), per day"
                  addonBefore="SIM limit per operator(Special Testing), per day"
                />
                <Input
                  value={stAdditionalSimLimit}
                  onChange={handleStAdditonalSimLimitChange}
                  placeholder="Additional SIM limit per operator(Special Testing), per day"
                  addonBefore="Additional SIM limit per operator(Special Testing), per day"
                />
                {/* <Input
                  value={bonusLimit}
                  onChange={handleBonusLimitChange}
                  placeholder="Bonus Limit"
                  addonBefore="Bonus Limit"
                /> */}

                <Input
                  value={payoutLowerLimit}
                  onChange={handlePayoutLowerLimitChange}
                  placeholder="Payout Lower Limit"
                  addonBefore="Payout Lower Limit"
                />
              </div>
              <Form.Item>
                <div className="flex justify-end">
                  <Button
                    type="secondary"
                    className="bg-[blue] text-[white] ml-2 mt-4"
                    htmlType="submit"
                  >
                    Edit Settings
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EditConfigSetting;
