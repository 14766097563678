import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Input, Space, Table, message } from "antd";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { ExpandAltOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Highlighter from "react-highlight-words";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Stats from "./tester-stats";

const apiUrl = process.env.REACT_APP_API_TesterStatsForTeamlead;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Testers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedTester, setSelectedTester] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleViewClick = (record) => {
    // Open the full-screen dialog with the selected tester details
    setSelectedTester(record);
    setOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);
  const sortedUsers = useMemo(() => {
    return [...testers].sort((a, b) => {
      if (sortOrder === "desc") {
        return b.otps_count - a.otps_count;
      } else {
        return a.otps_count - b.otps_count;
      }
    });
  }, [testers, sortOrder]);
  const fetchData = async () => {
    try {
      // Dynamically choose the API based on the userId from localStorage
      let apiEndpoint = apiUrl; // default endpoint
      if (user.userId === 478) {
        apiEndpoint =
          "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/special-testers-stats-for-team-lead";
      } else {
        apiEndpoint = `${apiUrl}/${user.userId}`;
      }

      // Fetch data from the appropriate endpoint
      const response = await fetch(apiEndpoint);
      if (!response.ok) {
        throw new Error("Failed to fetch testers");
      }
      const data = await response.json();

      // Check if data contains records
      if (data && data.length > 0) {
        setTesters(data);
        setLoading(false);
      } else {
        setTesters([]); // Set empty array to prevent errors when rendering the table
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Tester Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total Tests recieved this month",
      dataIndex: "current_month_otps_count",
      key: "current_month_otps_count",
      width: "10%",
      ...getColumnSearchProps("current_month_otps_count"),
      sorter: (a, b) => a.current_month_otps_count - b.current_month_otps_count,
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Tests recieved last month",
    //   dataIndex: "last_month_otps_count",
    //   key: "last_month_otps_count",
    //   width: "10%",
    //   ...getColumnSearchProps("last_month_otps_count"),
    //   sorter: (a, b) => a.last_month_otps_count - b.last_month_otps_count,
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Actions",
      dataIndex: "view",
      key: "view",
      width: "20%",
      render: (text, record) => (
        <Space>
          <Button
            icon={<ExpandAltOutlined />}
            onClick={() => {
              handleViewClick(record);
            }}
          >
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      {testers && (
        <>
          <Table
            columns={columns}
            dataSource={sortedUsers}
            loading={loading}
            locale={{
              emptyText: loading ? "Loading..." : "No Data",
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 5,
              pageSizeOptions: ["5", "10", "20"],
            }}
            scroll={{ x: "100%" }}
          />
        </>
      )}

      {!testers && <p>No records found.</p>}

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Tester Statistics Details
            </Typography>
            <Typography
              variant="p"
              component="div"
              style={{ justifyContent: "flex-end" }}
            >
              {selectedTester?.first_name} {selectedTester?.last_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid container style={{ width: "80%" }}>
              {selectedTester && <Stats id={selectedTester.id} />}
            </Grid>
          </Grid>
        </List>
      </Dialog>
    </>
  );
};

export default Testers;
