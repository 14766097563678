import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TotalOtpsCountGraph from "./TotalOtpsCountGraph";
import PerTeamOtpsCountGraph from "./PerTeamOtpsCountGraph";
import { useRole } from "./../../RoleContext";

const Container = styled.div`
  padding: 15px;
  max-width: 1400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (min-width: 900px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const CardContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin: 10px;
  padding: 20px;
  text-align: center;

  @media (max-width: 600px) {
    margin: 10px 0;
  }

  @media (min-width: 900px) {
    flex: 1;
    margin: 10px;
  }
`;

const CardTitle = styled.h3`
  color: #007bff;
  margin-bottom: 15px;
`;

const CardContent = styled.p`
  font-size: 1.2em;
  color: #333;
`;

const App = () => {
  const [data, setData] = useState({
    active_user_count: null,
    current_month_otp_count: null,
  });
  const { roleId } = useRole();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://qzufib1cx8.execute-api.us-east-1.amazonaws.com/dev/active-user-count"
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <TotalOtpsCountGraph
        title="Total OTPs Count"
        apiEndpoint="https://tg5n48dww1.execute-api.us-east-1.amazonaws.com/dev/all-opts-count-day-wise"
      />
      <PerTeamOtpsCountGraph
        title="Per Team OTPs Count"
        apiEndpoint="https://tg5n48dww1.execute-api.us-east-1.amazonaws.com/dev/team-opts-count-day-wise"
      />
      <CardContainer>
        <CardTitle>Active Testers</CardTitle>
        {data.active_user_count !== null ? (
          <CardContent>{data.active_user_count}</CardContent>
        ) : (
          <CardContent>Loading...</CardContent>
        )}
      </CardContainer>
      <CardContainer>
        <CardTitle>Current Month OTPs</CardTitle>
        {data.current_month_otp_count !== null ? (
          <CardContent>{data.current_month_otp_count}</CardContent>
        ) : (
          <CardContent>Loading...</CardContent>
        )}
      </CardContainer>
    </Container>
  );
};

export default App;
