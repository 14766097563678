import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Input, Space, Table, message } from "antd";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { ExpandAltOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Highlighter from "react-highlight-words";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Stats from "./tester-stats";

const apiUrl = process.env.REACT_APP_AllTestersOTPCount;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Testers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedTester, setSelectedTester] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleViewClick = (record) => {
    // Open the full-screen dialog with the selected tester details
    setSelectedTester(record);
    setOpen(true);
  };

  const handleDownloadCsv = async () => {
    await fetchData();
    let downloadData = sortedUsers.map((item) => ({
      First_Name: item.first_name,
      Last_Name: item.last_name,
      Last_Month_Valid_Count: item.last_month_valid_count,
      Payment: item.payment,
      Account_Title: item.account_title,
      Account_Number: item.account_number,
      Bank_Name: item.bank_name,
    }));

    const csvData = Papa.unparse(downloadData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Tests.csv");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  const calculatePayment = (count) => {
    let payment = 0;
    payment = count * 0.01;
    return payment.toFixed(3);
  };

  const sortedUsers = useMemo(() => {
    return [...testers].sort((a, b) => {
      if (sortOrder === "desc") {
        return b.after12_valid_count - a.after12_valid_count;
      } else {
        return a.after12_valid_count - b.after12_valid_count;
      }
    });
  }, [testers, sortOrder]);

  const fetchData = async () => {
    let url;
    const apiUrl = process.env.REACT_APP_OTP_count_all_testers_teamlead_wise;
    const storedUser1 = localStorage.getItem("user");
    const user1 = JSON.parse(storedUser1);
    if (user1.roleId == 8) {
      url =
        "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/previous-month-stats-for-team-lead";
    } else {
      url = `${apiUrl}?team_lead_id=${user.userId}`;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch testers data");
      }
      const data = await response.json();

      // Check if data contains records
      if (data && data.length > 0) {
        const testersWithPayment = data.map((tester) => ({
          ...tester,
          payment: calculatePayment(tester.last_month_valid_count),
        }));
        setTesters(testersWithPayment);
        setLoading(false);
      } else {
        setTesters([]); // Set empty array to prevent errors when rendering the table
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Tester Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Valid Tests Recieved Last Month",
      dataIndex: "last_month_valid_count",
      key: "last_month_valid_count",
      width: "10%",
      ...getColumnSearchProps("last_month_valid_count"),
      sorter: (a, b) => a.last_month_valid_count - b.last_month_valid_count,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Payment (USD)",
      dataIndex: "payment",
      key: "payment",
      width: "12%",
      sorter: (a, b) => a.payment - b.payment,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Account Title",
      dataIndex: "account_title",
      key: "account_title",
      width: "12%",
      ...getColumnSearchProps("account_title"),
      sorter: (a, b) => a.account_title.localeCompare(b.account_title),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      width: "12%",
      ...getColumnSearchProps("account_number"),
      sorter: (a, b) => a.account_number.localeCompare(b.account_number),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      width: "12%",
      ...getColumnSearchProps("bank_name"),
      sorter: (a, b) => a.bank_name.localeCompare(b.bank_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      dataIndex: "view",
      key: "view",
      width: "20%",
      render: (text, record) => (
        <Space>
          <Button
            icon={<ExpandAltOutlined />}
            onClick={() => {
              handleViewClick(record);
            }}
          >
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      <AppBar
        position="relative"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          padding: "0.5rem 1rem",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            OTP Count
          </Typography>
          <Button
            variant="contained"
            onClick={handleDownloadCsv}
            sx={{
              backgroundColor: "#3f51b5",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#303f9f",
              },
            }}
          >
            Download CSV
          </Button>
        </Toolbar>
      </AppBar>

      {testers && (
        <>
          <Table
            columns={columns}
            dataSource={sortedUsers}
            loading={loading}
            locale={{
              emptyText: loading ? "Loading..." : "No Data",
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 5,
              pageSizeOptions: ["5", "10", "20"],
            }}
            scroll={{ x: "100%" }}
          />
        </>
      )}

      {!testers && <p>No records found.</p>}

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Valid OTP count for all testers
            </Typography>
            <Typography
              variant="p"
              component="div"
              style={{ justifyContent: "flex-end" }}
            >
              {selectedTester?.first_name} {selectedTester?.last_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid container style={{ width: "80%" }}>
              {selectedTester && <Stats id={selectedTester.id} />}
            </Grid>
          </Grid>
        </List>
      </Dialog>
    </>
  );
};

export default Testers;
