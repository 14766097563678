import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message, Alert, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const apiUrl =
  "https://vthivie2x5.execute-api.us-east-1.amazonaws.com/dev/smsin-for-special-testers";
const dataApiUrl =
  "https://dkrqrfvlh3.execute-api.us-east-1.amazonaws.com/dev/opts-count-per-application";
const appsWithTimestampUrl =
  "https://dkrqrfvlh3.execute-api.us-east-1.amazonaws.com/dev/apps-with-timestamp";

const Testers = () => {
  const { Title } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [appTimestamps, setAppTimestamps] = useState({});

  const searchInput = useRef(null);

  useEffect(() => {
    fetchData();
    fetchChartData();
    fetchAppTimestamps();
  }, []);
  const handleClose = () => {
    setTesters(null);
    // setOpen(false);
    setLoading(false);
  };

  const fetchData = async () => {
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    try {
      const response = await fetch(`${apiUrl}/${user.userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch smsin");
      }
      const data = await response.json();
      const allowedSenderIds = [
        "WhatsApp",
        "Facebook",
        "FACEBOOK",
        "facebook",
        "32665",
        "1xbet",
        "1xbet.com",
        "Snapchat",
      ];
      if (user.id == 713) {
        // Filter the data based on allowed sender IDs
        const filteredData = data.body.filter((message) =>
          allowedSenderIds.includes(message.sender_id)
        );
        setTesters(filteredData);
      } else {
        setTesters(data.body);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching smsin:", error);
    }
  };
  const fetchChartData = async () => {
    try {
      const response = await fetch(dataApiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch chart data");
      }
      const data = await response.json();
      const formattedData = Object.keys(data).map((country) => {
        return {
          country,
          ...data[country],
        };
      });
      filterApps(data);
      setChartData(formattedData);
      filterApps(data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const fetchAppTimestamps = async () => {
    try {
      const response = await fetch(appsWithTimestampUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch app timestamps");
      }
      const data = await response.json();

      setAppTimestamps(data);
    } catch (error) {
      console.error("Error fetching app timestamps:", error);
    }
  };
  const filterApps = (data) => {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const filtered = ["ghana", "mali"].map((country) => {
      const apps = Object.entries(data[country])
        .filter(([app, count]) => count > 70)
        .map(([app, count]) => ({ app, count }));
      return { country: capitalize(country), apps };
    });
    setFilteredApps(filtered);
  };

  const filteredTesters = testers.filter((message) => {
    const countryApps = appTimestamps[message.country];
    // console.log("timestamp", message);

    if (!countryApps) return true; // Include message if no apps data found for the country
    const appTimestamp = countryApps[message.sender_id];
    return (
      !appTimestamp || new Date(message.timestamp) <= new Date(appTimestamp)
    );
  });

  // };
  // console.log("filteredTesters", filteredTesters);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "time_stamp",
      key: "time_stamp",
      width: "10%",
      ...getColumnSearchProps("time_stamp"),
      sorter: (a, b) => a.time_stamp.localeCompare(b.time_stamp),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "10%",
      ...getColumnSearchProps("country"),
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Operator Name",
      dataIndex: "operator_name",
      key: "operator_name",
      width: "10%",
      ...getColumnSearchProps("operator_name"),
      sorter: (a, b) => a.operator_name.localeCompare(b.operator_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "B Number",
      dataIndex: "destination_number",
      key: "destination_number",
      width: "10%",
      ...getColumnSearchProps("destination_number"),
      sorter: (a, b) => a.destination_number - b.destination_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "A Number",
      dataIndex: "sender_id",
      key: "sender_id",
      width: "10%",
      ...getColumnSearchProps("sender_id"),
      sorter: (a, b) => a.sender_id - b.sender_id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      width: "15%",
      ...getColumnSearchProps("content"),
      sorter: (a, b) => a.content - b.content,
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <>
      {contextHolder}

      {filteredTesters && (
        <>
          <Title level={4}>Messages for special testers</Title>
          <Table
            columns={columns}
            dataSource={testers}
            loading={loading}
            locale={{
              emptyText: loading ? "Loading..." : "No Data",
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 5,
              pageSizeOptions: ["5", "10", "20"],
            }}
            scroll={{ x: "100%" }}
          />
        </>
      )}
    </>
  );
};

export default Testers;
