import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file
import { Row, Col } from "antd";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const DemoColumn = () => {
  const [data, setData] = useState([]);
  const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
  const apiUrl =
    "https://j8pcg4bmwc.execute-api.us-east-1.amazonaws.com/dev/frequently-used-apps";

  const toMidnightUTC = (date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedRange.length !== 2) {
        return;
      }

      try {
        const startDate = toMidnightUTC(new Date(selectedRange[0]));
        const endDate = toMidnightUTC(new Date(selectedRange[1]));

        const response = await axios.post(apiUrl, {
          start_date: startDate.toISOString().split("T")[0],
          end_date: endDate.toISOString().split("T")[0],
        });

        const otpCount = response.data.otp_count;

        // Flatten the data into a format suitable for the chart
        const flattenedData = [];
        Object.entries(otpCount).forEach(([date, appCounts]) => {
          Object.entries(appCounts).forEach(([app, count]) => {
            flattenedData.push({
              date,
              app,
              count,
            });
          });
        });

        setData(flattenedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedRange]);

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedRange([startDate, endDate]);
  };

  // Group data by date and app for stacked bar chart
  const groupedData = data.reduce((acc, curr) => {
    acc[curr.date] = acc[curr.date] || {};
    acc[curr.date][curr.app] = curr.count;
    return acc;
  }, {});

  const dates = Object.keys(groupedData);
  const apps = Array.from(new Set(data.map((item) => item.app))); // Unique app names
  const datasets = apps.map((app) => ({
    label: app,
    data: dates.map((date) => groupedData[date][app] || 0),
    backgroundColor:
      {
        WhatsApp: "#22CBCC",
        Facebook: "#9C27B0",
        Instagram: "#FF5733",
        "1xbet": "#FFC300",
        Snapchat: "#a0eb1a",
      }[app] || "#CCCCCC",
  }));

  const chartData = {
    labels: dates,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow chart to fill container
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <div>
      <Typography variant="h6" component="div" style={{ marginBottom: "16px" }}>
        OTP Count App Wise
      </Typography>
      <Row>
        <Col
          xs={24}
          md={8}
          style={{ marginRight: "160px" }}
          className="rdrDefinedRangesWrapper"
        >
          <div className="statistics-container">
            <DateRangePicker
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: selectedRange[0],
                  endDate: selectedRange[1],
                  key: "selection",
                },
              ]}
              className="custom-date-range-picker"
            />
          </div>
        </Col>

        <Col
          xs={24}
          md={12} // Extend to full width on medium+ screens
          style={{
            height: "400px", // Set a fixed height for better scaling
            padding: "16px",
          }}
        >
          <Bar data={chartData} options={options} />
        </Col>
      </Row>
    </div>
  );
};

export default DemoColumn;
