import React, { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Menu,
  Dropdown,
  Modal,
  Form,
  Input,
  theme,
  Tabs,
} from "antd";
import axios from "axios";
import Testers from "./tester-teamlead/testers";
import TesterStats from "./special-testers-teamlead/testers-stats";
import AllTestersStats from "./tester-teamlead/allTestersOTPCount";
import SpecialTestersStats from "./tester-teamlead/specialTesterStats";
import AppWiseCount from "./tester-teamlead/appwiseCount";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Leaderboard from "./special-testers-teamlead/leaderBoard";
import billIcon from "../assets/images/Bill.png";
import leaderboardIcon from "../assets/images/Leaderboard.png";
import otpStatsIcon from "../assets/images/Combo Chart.png";
import TesterstatisticsIcon from "../assets/images/Leaderboard.png";
import testerIcon from "../assets/images/User Groups.png";
import appsIcon from "../assets/images/Keypad.png";
const { Header, Content, Footer } = Layout;
const { Item } = Form;
const updatePassword = process.env.REACT_APP_API_UpdatePassword;
const TeamleadDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem("setupTime");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  const handleMenuSelect = (key) => {
    setSelectedMenuItem(key);
  };

  const handlePasswordChange = () => {
    setChangePasswordVisible(true);
  };

  const handleCancel = () => {
    setChangePasswordVisible(false);
  };

  const handleFinish = async (values) => {
    try {
      const { currentPassword, newPassword } = values;
      const response = await axios.put(updatePassword, {
        userId: user.userId,
        oldPassword: currentPassword,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        setChangePasswordVisible(false);
      } else {
        console.error("Password change failed");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="changePassword"
        icon={<SettingOutlined />}
        onClick={handlePasswordChange}
      >
        Change Password
      </Menu.Item>
      <Menu.Item key="logout" onClick={onLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={items}
          onClick={({ key }) => handleMenuSelect(key)}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="link">
            {user.name
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Button>
        </Dropdown>
      </Header>
      <Content
        style={{
          padding: "0 48px",
        }}
      >
        <View1 />
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        EON Testing Tool ©{new Date().getFullYear()} Created by EON Intelligence
      </Footer>

      {/* Change Password Modal */}
      <Modal
        title="Change Password"
        visible={changePasswordVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="changePasswordForm"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="confirmNewPassword"
            label="Confirm New Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Item>
          <Item>
            <Button
              type="secondary"
              className="bg-[blue] text-[white]"
              htmlType="submit"
            >
              Change Password
            </Button>
          </Item>
        </Form>
      </Modal>
    </Layout>
  );
};

const View1 = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onChangeTab = (key) => {
    setSelectedMenuTabItem(key);
  };
  const [tabPosition, setTabPosition] = useState("left");
  const [selectedMenuTabItem, setSelectedMenuTabItem] = useState("1");
  useEffect(() => {
    const handleResize = () => {
      // Switch to horizontal tabs for small screens
      if (window.innerWidth <= 768) {
        setTabPosition("top");
      } else {
        // Default to vertical tabs for larger screens
        setTabPosition("left");
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userId = user.userId; // Retrieve userId from session storage

  const items = [
    {
      key: "1",
      label: `All Testers`,
      component: <Testers />,
      icon: testerIcon,
    },
    {
      key: "2",
      label: `Testers Statistics`,
      component: <TesterStats />,
      icon: TesterstatisticsIcon,
    },

    {
      key: "3",
      label: `Leader Board`,
      component: <Leaderboard />,
      icon: leaderboardIcon,
    },
    {
      key: "4",
      label: `Payment and Valid OTP for last month`,
      component: <AllTestersStats />,
      icon: billIcon,
    },
  ];

  // Conditionally add the special testers tab for userId 478
  if (userId === 478) {
    items.push({
      key: "7",
      label: `Special Testers Stats`,
      component: <SpecialTestersStats />,
      icon: otpStatsIcon,
    });
    items.push({
      key: "8",
      label: `App wise count`,
      component: <AppWiseCount />,
      icon: otpStatsIcon,
    });
  }

  return (
    <div
      style={{
        padding: 24,
        minHeight: 380,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <Tabs
        activeKey={selectedMenuTabItem}
        onChange={onChangeTab}
        type="card"
        tabPosition={tabPosition}
        style={{ minHeight: 200 }}
      >
        {items.map((item) => (
          <Tabs.TabPane
            key={item.key}
            tab={
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={item.icon}
                  alt={item.label}
                  style={{ width: "16px", marginRight: "8px" }}
                />
                {item.label}
              </span>
            }
          >
            {item.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

const items = [
  {
    key: "1",
    label: `EON Testing Tool`,
    component: View1,
  },
];

export default TeamleadDashboard;
