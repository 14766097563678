import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Select, Radio, Button, Form, message, Col } from "antd";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const AddTesterForm = ({ setOpen }) => {
  const teamsURL = process.env.REACT_APP_API_Team;
  const apiUrl = process.env.REACT_APP_API_Testers;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(teamsURL);
      setTeams(response.data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const selectedTeam = teams.find((t) => t.name === values.team);
      if (!selectedTeam) {
        throw new Error("Selected team not found.");
      }

      const role_id = values.type === "tester" ? 4 : 7; // Setting role_id based on tester type
      const team_id = selectedTeam.id;

      const payload = {
        ...values,
        role_id: role_id,
        team_id,
      };

      if (role_id === 7) {
        const apiUrl =
          "https://1no3dnnvel.execute-api.us-east-1.amazonaws.com/dev/add-special-testes";
        const response = await axios.post(apiUrl, payload);

        if (response.status === 200 && response.data.statusCode !== 400) {
          setOpen(false);
          console.log("response:", response.data.statusCode);
          message.success("Special Tester added successfully");
        } else if (
          response.status === 200 &&
          response.data.statusCode === 400
        ) {
          message.error("Failed to add Special Tester. Email already exists");
        } else {
          message.error("Failed to add Special Tester.");
        }
      } else {
        // Regular flow when "Tester" is selected
        const response = await axios.post(apiUrl, payload);

        if (response.data.body.statusCode === 400) {
          message.error("Email already exists, please use a different email.");
        } else if (response.status === 200) {
          setOpen(false);
          message.success("Tester added successfully.");
        }
      }
    } catch (error) {
      console.error("Error adding tester:", error);

      if (
        error.response &&
        error.response.data.message === "Email already exists."
      ) {
        message.error("Email already exists, please use a different email.");
      } else {
        message.error("Failed to add tester.");
      }
    }
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Tester
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <Form
              form={form}
              onFinish={handleSubmit}
              initialValues={{
                gender: "male", // set default gender if needed
              }}
              className="mx-10"
            >
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">Add Tester</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Form.Item
                  name="type"
                  rules={[
                    { required: true, message: "Please select Tester Type" },
                  ]}
                >
                  <Select placeholder="Select Tester Type">
                    <Select.Option value="tester">Tester</Select.Option>
                    <Select.Option value="specialtester">
                      Special Tester
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="first_name"
                  rules={[
                    { required: true, message: "Please enter First Name" },
                  ]}
                >
                  <Input
                    placeholder="Enter First Name"
                    addonBefore="First Name"
                  />
                </Form.Item>
                <Form.Item
                  name="last_name"
                  rules={[
                    { required: true, message: "Please enter Last Name" },
                  ]}
                >
                  <Input
                    placeholder="Enter Last Name"
                    addonBefore="Last Name"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter Email" },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                >
                  <Input placeholder="Enter Email" addonBefore="Email" />
                </Form.Item>
                <Form.Item
                  name="contact_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Contact Number",
                    },
                    {
                      pattern: /^(?:\+?92|0)\d{10}$/,
                      message: "Please enter a valid contact number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Contact Number"
                    addonBefore="Contact Number"
                  />
                </Form.Item>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Please enter Password" },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters long",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter Password"
                      addonBefore="Password"
                      iconRender={(visible) =>
                        visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      addonBefore="Confirm Password"
                      iconRender={(visible) =>
                        visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
                      }
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  name="gender"
                  rules={[{ required: true, message: "Please select Gender" }]}
                >
                  <Radio.Group>
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="team"
                  rules={[{ required: true, message: "Please select Team" }]}
                >
                  <Select placeholder="Select Team">
                    {teams.map((team) => (
                      <Select.Option key={team.id} value={team.name}>
                        {team.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="bank_name"
                  rules={[
                    { required: true, message: "Please enter Bank Name" },
                  ]}
                >
                  <Input
                    placeholder="Enter Bank Name"
                    addonBefore="Bank Name"
                  />
                </Form.Item>
                <Form.Item
                  name="account_title"
                  rules={[
                    { required: true, message: "Please enter Account Title" },
                  ]}
                >
                  <Input
                    placeholder="Enter Account Title"
                    addonBefore="Account Title"
                  />
                </Form.Item>
                <Form.Item
                  name="account_number"
                  rules={[
                    { required: true, message: "Please enter Account Number" },
                    {
                      max: 35,
                      message: "Account number must be of valid length",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Account Number"
                    addonBefore="Account Number"
                  />
                </Form.Item>
              </div>
              <div className="flex justify-end">
                <Button
                  type="secondary"
                  className="bg-[blue] text-[white]"
                  htmlType="submit"
                >
                  Add Tester
                </Button>
              </div>
            </Form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default AddTesterForm;
