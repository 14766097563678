import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Input, Space, Table, message, Upload } from "antd";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { ExpandAltOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Highlighter from "react-highlight-words";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Stats from "./tester-stats";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Testers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedTester, setSelectedTester] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);
  const apiUrl =
    "https://6ueej8dhwa.execute-api.us-east-1.amazonaws.com/dev/payment-csv-upload";
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleViewClick = (record) => {
    // Open the full-screen dialog with the selected tester details
    setSelectedTester(record);
    setOpen(true);
  };

  const fetchCurrencyRate = async () => {
    try {
      const response = await axios.get(
        "https://kuhkta502k.execute-api.us-east-1.amazonaws.com/dev/config-settings-apis"
      ); // Replace with actual API URL
      if (response.status === 200 && response.data.body) {
        return response.data.body.currency_rate; // Return the currency rate
      }
    } catch (error) {
      console.error("Error fetching currency rate:", error);
    }
    return null; // Return null if there's an error
  };

  const handleDownloadCsv = async () => {
    await fetchData(); // Ensure the latest data is fetched
    let downloadData = sortedUsers
      .filter((item) => item.last_month_valid_count > 0) // Exclude records with count 0
      .map((item) => ({
        First_Name: item.first_name,
        Last_Name: item.last_name,
        Last_Month_Valid_Count: item.last_month_valid_count,
        "Payment(in USD)": item.payment,
        "Payment(in Pkr)": item.payment_in_pkr
          ? item.payment_in_pkr.toFixed(2)
          : "", // Use the calculated payment in PKR
      }));

    const csvData = Papa.unparse(downloadData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Payment_Details.csv");
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle file upload
  const handleUpload = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csv = e.target.result;
        const { data } = Papa.parse(csv, { header: true });

        if (data && data.length > 0) {
          // Preprocess data: trim spaces from `first_name` and `last_name`, and remove empty cells
          const processedData = data.map((row) => {
            return Object.fromEntries(
              Object.entries(row)
                .map(([key, value]) => [
                  typeof key === "string" ? key.trim() : key,
                  typeof value === "string" ? value.trim() : value,
                ])
                .filter(([, value]) => value) // Remove entries with empty values
            );
          });

          // Make sure data is not empty
          if (data && data.length > 0) {
            try {
              // Send the parsed data to the backend API
              const response = await axios.post(apiUrl, processedData);
              if (response.status === 200) {
                const { successful_updates, failed_updates, failed_details } =
                  response.data;

                // Show success message if there were successful updates
                if (successful_updates > 0) {
                  message.success(
                    "CSV data uploaded and processed successfully!"
                  );
                }

                // Handle failed updates by displaying each error message
                if (failed_updates > 0 && failed_details.length > 0) {
                  failed_details.forEach((failure) => {
                    const fullName = `${failure.First_Name || ""} ${
                      failure.Last_Name || ""
                    }`.trim();
                    const reason = failure.reason;
                    messageApi.error(
                      `Record for ${fullName} not uploaded, ${reason}`
                    );
                  });
                }

                fetchData(); // Reload table data after successful upload
              } else {
                message.error("Failed to upload CSV data");
              }
            } catch (error) {
              console.error("Error uploading CSV data:", error);
              message.error("Error uploading CSV data");
            }
          }
        } else {
          message.error("CSV file is empty or invalid");
        }
        resolve();
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };
  // Upload component props
  const uploadProps = {
    name: "file",
    accept: ".csv",
    beforeUpload: (file) => {
      handleUpload(file);
      return false; // Prevent automatic upload
    },
  };

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  const calculatePayment = (count) => {
    const paymentUSD = (count * 0.01).toFixed(3);
    return paymentUSD;
  };

  const sortedUsers = useMemo(() => {
    return [...testers].sort((a, b) => {
      if (sortOrder === "desc") {
        return b.last_month_valid_count - a.last_month_valid_count;
      } else {
        return a.last_month_valid_count - b.last_month_valid_count;
      }
    });
  }, [testers, sortOrder]);

  const fetchData = async () => {
    let url;
    const dataAPIURL =
      process.env.REACT_APP_OTP_count_all_testers_teamlead_wise;
    const storedUser1 = localStorage.getItem("user");
    const user1 = JSON.parse(storedUser1);
    if (user1.roleId == 8) {
      url =
        "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/previous-month-stats-for-team-lead";
    } else {
      url = `https://6ueej8dhwa.execute-api.us-east-1.amazonaws.com/dev/all-testers-count`;
    }

    try {
      // Fetch testers data
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch testers data");
      }
      const data = await response.json();

      // Fetch currency rate
      const fetchedCurrencyRate = await fetchCurrencyRate();
      if (!fetchedCurrencyRate) {
        message.error("Failed to fetch currency rate");
      }

      // Check if data contains records
      if (data && data.length > 0) {
        const testersWithPayment = data.map((tester) => ({
          ...tester,
          payment: calculatePayment(tester.last_month_valid_count),
          payment_in_pkr: fetchedCurrencyRate
            ? calculatePayment(tester.last_month_valid_count) *
              fetchedCurrencyRate
            : null, // Calculate payment in PKR
        }));
        setTesters(testersWithPayment);
        setLoading(false);
      } else {
        setTesters([]); // Set empty array to prevent errors when rendering the table
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Update Columns to Include Payment in PKR
  const columns = [
    {
      title: "Tester Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Valid Tests Recieved Last Month",
      dataIndex: "last_month_valid_count",
      key: "last_month_valid_count",
      width: "10%",
      ...getColumnSearchProps("last_month_valid_count"),
      sorter: (a, b) => a.last_month_valid_count - b.last_month_valid_count,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Payment (USD)",
      dataIndex: "payment",
      key: "payment",
      width: "12%",
      sorter: (a, b) => a.payment - b.payment,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Payment (PKR)",
      dataIndex: "payment_in_pkr",
      key: "payment_in_pkr",
      width: "12%",
      render: (text) => (text ? text.toFixed(2) : "N/A"),
      sorter: (a, b) => a.payment_in_pkr - b.payment_in_pkr,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Account Title",
      dataIndex: "account_title",
      key: "account_title",
      width: "12%",
      ...getColumnSearchProps("account_title"),
      sorter: (a, b) => a.account_title.localeCompare(b.account_title),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      width: "12%",
      ...getColumnSearchProps("account_number"),
      sorter: (a, b) => a.account_number.localeCompare(b.account_number),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      width: "12%",
      ...getColumnSearchProps("bank_name"),
      sorter: (a, b) => a.bank_name.localeCompare(b.bank_name),
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <>
      {contextHolder}

      <AppBar
        position="relative"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          padding: "0.5rem 1rem",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Left-aligned OTP Count Text */}
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            Details
          </Typography>

          {/* Right-aligned Buttons */}
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              onClick={handleDownloadCsv}
              sx={{
                backgroundColor: "#3f51b5",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#303f9f",
                },
              }}
            >
              Download Sample CSV
            </Button>

            <Upload {...uploadProps}>
              <Button
                icon={<UploadOutlined />}
                sx={{ borderColor: "#3f51b5", color: "#3f51b5" }}
              >
                Upload Payment CSV
              </Button>
            </Upload>
          </div>
        </Toolbar>
      </AppBar>

      {testers && (
        <>
          <Table
            columns={columns}
            dataSource={sortedUsers}
            loading={loading}
            locale={{
              emptyText: loading ? "Loading..." : "No Data",
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 5,
              pageSizeOptions: ["5", "10", "20"],
            }}
            scroll={{ x: "100%" }}
          />
        </>
      )}

      {!testers && <p>No records found.</p>}

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Valid OTP count for all testers
            </Typography>
            <Typography
              variant="p"
              component="div"
              style={{ justifyContent: "flex-end" }}
            >
              {selectedTester?.first_name} {selectedTester?.last_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid container style={{ width: "80%" }}>
              {selectedTester && <Stats id={selectedTester.id} />}
            </Grid>
          </Grid>
        </List>
      </Dialog>
    </>
  );
};

export default Testers;
